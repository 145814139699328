import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type CarrierUserContextDTO, type UserProfile } from '@fv/models'
import { carrierContextKeys, userContextKeys } from '@/queryKeys'

import { type CarrierInfo } from '../../types/Context'
import { carrierFetch } from '../../utils/carrierFetch'

interface CarrierUserProfileArgs {
  carrierName: string
  profile: UserProfile
}

interface Response {
  profile?: UserProfile
  carrierId?: string
  carrierName?: string
}

async function updateProfile({ carrierName, profile }: CarrierUserProfileArgs) {
  return await carrierFetch<Response>(`/users/profile`, {
    method: 'PUT',
    body: { carrierName, profile },
  })
}

export function useUpdateProfile() {
  const queryClient = useQueryClient()

  return useMutation(updateProfile, {
    onSuccess: ({ carrierId, carrierName, profile }) => {
      queryClient.setQueryData<CarrierInfo>(
        carrierContextKeys.id(carrierId),
        (prev = { _id: '', name: '' }) => ({
          ...prev,
          name: carrierName,
        }),
      )

      queryClient.setQueryData<CarrierUserContextDTO>(
        userContextKeys.all,
        prev => ({
          ...(prev && prev),
          user: {
            ...prev?.user,
            profile,
          },
        }),
      )
      toast.success('Updated profile successfully.')
    },
  })
}
