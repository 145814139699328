import dayjs from 'dayjs'
import UAParser from 'ua-parser-js'

import { FvButton } from '@fv/client-components'
import { type CarrierSession } from '@fv/models'
import { type DTO } from '@fv/models/core'

const parseUserAgent = (userAgent?: string) =>
  new UAParser(userAgent).getResult()

type SessionsProps = {
  revokeSession: (id: string | null) => void
  sessions: DTO<CarrierSession>[]
}

export const Sessions = ({ revokeSession, sessions }: SessionsProps) => {
  if (!sessions) return null

  return (
    <>
      <header className="header-divider mb-1">Active sessions</header>
      <ul className="divide-y divide-y-200">
        {sessions.map(s => {
          const userAgent = parseUserAgent(s.userAgent)

          return (
            <li
              key={s._id}
              className="py-4 grid grid-cols-[repeat(2,18rem),8rem,6rem] whitespace-nowrap gap-x-10 b950:grid-cols-[minmax(20rem,1fr)_1fr_1fr] b650:grid-cols-1  b650:gap-y-2"
            >
              <span className="b950:col-span-3 b950:pb-2 b650:col-span-1 b650:pb-0 ">
                {userAgent?.browser?.name} {userAgent?.browser?.major} on{' '}
                {userAgent?.os?.name}
              </span>
              <span>
                {dayjs(s.lastUsed).format('MMM D, YYYY h:mm A')}
                <span className="text-xs">
                  {' '}
                  ({dayjs(s.lastUsed).fromNow()})
                </span>
              </span>
              <span>{s.ip}</span>
              <span>
                {s.active ? (
                  <FvButton
                    onClick={() => {
                      revokeSession(null)
                    }}
                    icon="times"
                  >
                    <span>Log out</span>
                  </FvButton>
                ) : (
                  <FvButton
                    onClick={e => {
                      e.preventDefault()
                      revokeSession(s._id)
                    }}
                    icon="times"
                  >
                    <span>Revoke</span>
                  </FvButton>
                )}
              </span>
            </li>
          )
        })}
      </ul>
    </>
  )
}
