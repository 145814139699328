import { ObjectId } from '@freightview/object-id'

const knownObjectTypes = [Date, ObjectId]

function isKnownObject(obj: any) {
  return knownObjectTypes.some(k => obj instanceof k)
}

function isNotNull(obj: any) {
  return typeof obj === 'object' && obj !== null
}

export function removeUndef<T>(obj: T, removeNull = false): T {
  if (isKnownObject(obj)) return obj

  if (isNotNull(obj)) {
    const entries = Object.entries(obj)
    const noUndef = entries
      .map(([k, v]) => {
        const newVal = removeUndef(v)
        return [k, newVal]
      })
      .filter(([_, v]) => v !== undefined && (!removeNull || v !== null))
    let newObj = Object.fromEntries(noUndef)
    if (Array.isArray(obj)) newObj = Object.values(newObj)
    return newObj
  }
  return obj
}
